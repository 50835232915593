import imageCompression from "browser-image-compression";

import Amplify, {API, Storage} from 'aws-amplify';
import awsconfig from '../../../amplify_src/aws-exports';
import {getBookshelfAssessment} from "../../../amplify_src/graphql/queries";
import {onCreateBookshelfAssessment} from "../../../amplify_src/graphql/subscriptions";

const uuidv4 = require('uuid/v4');
const path = require('path');

const bookshelfScanMutations = {
    mutateBookshelfScanImageFile(state, file) {
        state.bookshelfScanImageFile = file;
    },
    mutateBookshelfScanCompressImage(state, file) {
        state.compressImage = file;
    },
    mutateCameraDialog(state, isOpen) {
        state.cameraDialog = isOpen;
    },
    mutateUploadProgress(state, progress) {
        state.uploadProgress = progress;
    },
    mutateScanResult(state, payload) {
        const spineNo = payload['spineNo'];
        const result = payload['result'];
        if (!state.addedId.has(result.id)) {
            const items = new Array();
            items.push(result);
            if (result.other && result.other.length > 0) {

                for (const index in result.other) {
                    items.push(result.other[index]);
                }
            }
            state.scanResult.splice(spineNo, 1, items);
            state.addedId.add(result.id);
        }
    },
    updateApiProgress(state, { spineNo, progress }) {
        state.apiProgress[spineNo] = progress;
    },
    // 全体の進捗を計算するmutation
    updateOverallProgress(state) {
        // 各API呼び出しの進捗を計算
        const totalApiProgress = Object.values(state.apiProgress).reduce((sum, progress) => sum + progress, 0);
        state.overallProgress = totalApiProgress / state.totalSpineCount;
    },
    initializeApiProgress(state, count) {
        state.apiProgress = new Array(count).fill(0);
    },
    resetScanResult(state) {
        state.scanResult = new Array(100);
        state.addedId = new Set();
        state.apiProgress = [];
        state.bookshelfScanImageFile = null;
        state.compressImage = null;
        state.uploadProgress = 0;
        state.overallProgress = 0;
    },
    mutateSubscribingFiles(state, { targetFileName, client, timeout }) {
        state.subscribingFiles.set(targetFileName, [client, timeout]);
    },
    mutateBookshelfAssessmentId(state, id) {
        state.bookshelfAssessmentId = id;
    },
    mutateScanDialog(state, isOpen) {
        state.scanDialog = isOpen;
    },
    mutateBackendErrorDialog(state, isError) {
        state.backendErrorDialog = isError;
    }
}
const bookshelfActions = {
    openDialog(context) {
        context.commit('mutateCameraDialog', true)
    },
    closeDialog(context) {
        context.commit('mutateCameraDialog', false)
    },
    async updateBookshelfScanImage({dispatch, commit}, e) {
        commit('mutateBookshelfScanImageFile', e);
        await dispatch('closeDialog')
            .then(() => {
                    return dispatch('compressFile', {'file': e});
                }
            ).then(value => {
                return dispatch('assessBookshelf', {'imageFile': value});
            })
    },
    async compressFile(context, payload) {
        let originalFile = payload['file']
        const MAX_WIDTH_OR_HEIGHT = 1024;
        const options = {
            maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT // 最大画像幅もしくは高さ
        };
        // 圧縮画像の生成
        const compressedImage = await imageCompression(originalFile, options);
        context.commit('mutateBookshelfScanCompressImage', compressedImage);
        // 圧縮画像を使ってアップロード
        return compressedImage;
    },

    /**
     * 本棚画像をS3に保存し、おためし査定画面へ遷移
     * @param imageFile 本棚画像
     * @returns {Promise<void>}
     */
    async assessBookshelf({dispatch, commit}, payload) {
        const DEFAULT_SEPARATION_COUNT = 50;  // 期待される画像分割サイズ(この値を元に、1段目直後の2段目Subscriptionを実行)
        Amplify.configure(awsconfig);

        // apiProgressを初期化
        commit('initializeApiProgress', DEFAULT_SEPARATION_COUNT);

        let imageFile = payload['imageFile']
        const currentUUID = uuidv4();
        const dt = new Date();
        const dateTimeNow =
            dt.getFullYear() +
            ("00" + (dt.getMonth() + 1)).slice(-2) +
            ("00" + dt.getDate()).slice(-2) +
            ("00" + dt.getHours()).slice(-2) +
            ("00" + dt.getMinutes()).slice(-2) +
            ("00" + dt.getSeconds()).slice(-2);
        const extname = path.extname(imageFile.name);
        const fileParts = `${currentUUID}_${dateTimeNow}`
        const fullPath = `${currentUUID}/${fileParts}${extname}`;
        for (let spineNo = 0; spineNo < DEFAULT_SEPARATION_COUNT; spineNo++) {

            // 分割番号をフォーマット(5桁の数字、0埋めあり)
            const formattedSpineNo = spineNo.toString().padStart(5, '0');

            const key = `${fileParts}_spine${formattedSpineNo}${extname}`;

            dispatch('subscribe', {
                'targetFileName': key,
                'spineNo': spineNo,
            })

        }

        commit('mutateBookshelfAssessmentId', fileParts);

        return Storage.put(fullPath, imageFile, {
            async progressCallback(progress) {
                const progressPercent = Math.floor(parseInt(progress.loaded, 10) / parseInt(progress.total, 10) * 100);
                commit('mutateUploadProgress', progressPercent);
                // GTMにアップロード判定用のレイヤーをプッシュ
                if (progressPercent === 100) {
                    window.dataLayer = window.dataLayer || [];
                    dataLayer.push({'gtmScanFileUpload': 'true'});
                }
            }
        })

    },
    /**
     * 指定したファイル名をキーに、DynamoDBからデータを習得する
     * @param fileName 検索対象キーとなるファイル名
     * @returns {Promise<GraphQLResult> | <object>}
     */
    fetchDynamoDB(fileName) {
        return API.graphql({
            query: getBookshelfAssessment,
            authMode: 'AWS_IAM',
            variables: {
                file: fileName
            }
        })
    },

    /**
     * 指定したファイル名に対してSubscriptionを実行し、受信後にコールバックを実行するメソッド
     * @param targetFileName Subscriptionのキーとして使うファイル名
     * @param callback 受信後に行う処理が実装されたコールバック
     * @param timeoutSec Subscriptionをタイムアウトするミリ秒
     * @returns {Promise<void>}
     */
    async subscribe({dispatch, commit, state}, payload) {
        const targetFileName = payload['targetFileName'];
        const spineNo = payload['spineNo'];

        // API呼び出し開始時に進捗を0%にする
        commit('updateApiProgress', { spineNo, progress: 0 });

        const client = API.graphql(
          {
              query: onCreateBookshelfAssessment,
              authMode: 'AWS_IAM',
              variables: {
                  // キーは、S3にあるディレクトリ名無しのファイル名
                  file: targetFileName
              }
          }
        ).subscribe({
            next: (response) => {
                if (response.value.data.onCreateBookshelfAssessment.result) {
                    const scan_result = JSON.parse(response.value.data.onCreateBookshelfAssessment.result).result_list[0];
                    commit('mutateScanResult', {
                        'result': scan_result,
                        'spineNo': spineNo
                    })

                    // API呼び出し完了時に進捗を100%にする
                    commit('updateApiProgress', { spineNo, progress: 100 });
                    commit('updateOverallProgress');
                    dispatch('unsubscribe', targetFileName);
                }
            },
            error: (err) => {
                dispatch('unsubscribe', targetFileName);
                this.mutateBackendErrorDialog(true);
            },
            close: () => {
                // closeの場合は、Subscriptionが終了していると考えられるため、unsubscribe()しない
                commit('mutateSubscribingFiles', { targetFileName, client: null, timeout: null });
                this.mutateBackendErrorDialog(true);
            }
        });

        // タイムアウト処理
        const timeout = setTimeout(() => {
            commit('updateApiProgress', { spineNo, progress: 100 });
            commit('updateOverallProgress');
            dispatch('unsubscribe', targetFileName);
        }, 20000);

        commit('mutateSubscribingFiles', { targetFileName, client, timeout });
    },
    /**
     * Subscribeを停止し、タイムアウトも削除
     * タイミングによっては、すでに停止している可能性もあるため、その場合は処理しない
     * @param state
     * @param commit
     * @param targetFileName
     * @returns {boolean} true:Subscribeを停止、false:すでに停止されていた
     */
    unsubscribe({state, commit}, targetFileName) {
        const subscribing = state.subscribingFiles.get(targetFileName);
        if (subscribing != null) {

            subscribing[0].unsubscribe();
            clearTimeout(subscribing[1]);
            commit('mutateSubscribingFiles', { targetFileName, client: null, timeout: null });

            return true;
        }

        // すでにunsubscribe済の場合
        return false;
    },

    async createSampleFileObject({state, commit}, inputImage){
        return axios.get(inputImage, {
            responseType: "arraybuffer"
        }).then(async (response) => {
            const options = {
                maxWidthOrHeight: '1024' // 最大画像幅もしくは高さ
            };
            const arrayBuffer = response.data;
            // Fileオブジェクトを生成する
            const file = new File([arrayBuffer], "", {type: "image/jpeg"});
            // 圧縮画像の生成
            const compressedImage = await imageCompression(file, options);
            return compressedImage;
        }).catch(err => {
            console.log('ImageFile get error');
            return false;
        });
    },

    async saveStorage({ state, commit }, file) {
        const SESSION_STORAGE_KEY_OF_UPLOAD_FILE = 'bookshelfScanImage';

        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = async () => {
                try {
                    // ストレージを初期化
                    sessionStorage.removeItem(SESSION_STORAGE_KEY_OF_UPLOAD_FILE);

                    const base64String = reader.result;
                    // Base64文字列をセッションストレージに保存
                    sessionStorage.setItem(SESSION_STORAGE_KEY_OF_UPLOAD_FILE, base64String);

                    resolve(true); // 保存成功
                } catch (error) {
                    console.error('保存エラー:', error);
                    resolve(false); // 保存失敗
                }
            };
        });
    },
}

const bookshelfGetters = {
    getImageUrl: (state) => {
        return state.bookshelfScanImageFile ? window.URL.createObjectURL(state.bookshelfScanImageFile) : null;
    },
    getUploadProgress: (state) => {
        return Math.ceil(Number.isNaN(state.uploadProgress) ? 0 : state.uploadProgress);
    },
    getScanResult: (state) => {
        const scanResult = state.scanResult.filter(item => item !== undefined && item !== null);
        return scanResult.map((items) => {
            return items.map((item) => {
                // 返却するデータは先頭のみ
                return {
                    ...item,
                    vs_catalog_id: item.id,
                    image_url: item.image_url ? [item.image_url] : [],
                    appraisal_price: item.appraisal_price ? item.appraisal_price : 0,
                }
            })[0]
        })
    },
    getOverallProgress: (state) => {
        return Math.ceil(Number.isNaN(state.overallProgress) ? 0 : state.overallProgress);
    },
    getBookshelfAssessmentId: (state) => {
        return state.bookshelfAssessmentId;
    },
    getScanDialog: (state) => {
        return state.scanDialog;
    },
    getBackendErrorDialog: (state) => {
        return state.backendErrorDialog;
    }
}
const moduleBookshelfScan = {
    namespaced: true,
    state: {
        bookshelfScanImageFile: null,
        compressImage: null,
        cameraDialog: false,
        uploadProgress: 0,
        scanResult: new Array(100),
        addedId: new Set(),
        apiProgress: [],
        totalSpineCount: 50,
        overallProgress: 0,
        subscribingFiles: new Map(),
        bookshelfAssessmentId: null,
        scanDialog: false,
        backendErrorDialog: false,
    },
    mutations: bookshelfScanMutations,
    actions: bookshelfActions,
    getters: bookshelfGetters,
}


export {moduleBookshelfScan};